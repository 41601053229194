import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Divider } from "react-native-elements";



type MetricKey = "quotes_accepted" | "quotes_pending" | "orders_completed" | "orders_cancelled";

interface MatrixData {
        all: Record<MetricKey, number>;
        week: Record<MetricKey, number>;
        month: Record<MetricKey, number>;
}

const titleToMetricKeyMap: Record<
        "Quotes Accepted" | "Quotes Pending" | "Order Completed" | "Order Cancelled",
        MetricKey
> = {
        "Quotes Accepted": "quotes_accepted",
        "Quotes Pending": "quotes_pending",
        "Order Completed": "orders_completed",
        "Order Cancelled": "orders_cancelled",
};

const CardPage = ({
        title,
        icon,
        matrixData,
}: {
        title: "Quotes Accepted" | "Quotes Pending" | "Order Completed" | "Order Cancelled";
        icon: string;
        matrixData: MatrixData;
}) => {
        const [selectedPeriod, setSelectedPeriod] = useState<keyof MatrixData>("all");

        const [buttonStyles, setButtonStyles] = useState([
                { txtcolor: "#4D4ACA", bgcolor: "#E3E3F4" },
                { txtcolor: "#1C1D24", bgcolor: "#F2F3F6" },
                { txtcolor: "#1C1D24", bgcolor: "#F2F3F6" },
        ]);

        const handleButtonClick = (index: number, period: keyof MatrixData) => {
                setSelectedPeriod(period);
                const updatedStyles = buttonStyles.map((style, i) =>
                        i === index
                                ? { txtcolor: "#4D4ACA", bgcolor: "#E3E3F4" }
                                : { txtcolor: "#1C1D24", bgcolor: "#F2F3F6" }
                );
                setButtonStyles(updatedStyles);
        };

        const data = matrixData[selectedPeriod][titleToMetricKeyMap[title]];

        return (
                <div>
                        <Card>
                                <CardContent> <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                }}><Typography
                                        className="title"
                                        color="textSecondary"
                                        gutterBottom
                                        style={{
                                                fontSize: "30px",
                                                color: "#5300eb",
                                                fontWeight: "bold",
                                        }}
                                >{data}</Typography>
                                        <Typography className="icon" color="textSecondary" gutterBottom>
                                                <div style={{ color: "#FF8D27" }}>
                                                        <img src={icon} alt={title} />
                                                </div>
                                        </Typography>
                                </div>

                                        <Typography color="textSecondary" style={{color:"#1C1D24",fontSize:"16px",fontWeight:500}}>
                                                <div>{title}</div>
                                        </Typography>
                                </CardContent>
                               <div style={{padding:"0 16px"}}> <Divider /></div>
                                <div
                                        style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-evenly",
                                                paddingBottom: "10px",
                                                marginTop: "21px"}}>
                                        {(["all", "week", "month"] as (keyof MatrixData)[]).map((label, index) => (
                                                <button
                                                        key={index}
                                                        onClick={() => handleButtonClick(index, label)}
                                                        style={{color: buttonStyles[index].txtcolor,
                                                                backgroundColor: buttonStyles[index].bgcolor,
                                                                borderRadius: "20px",
                                                                padding: "6.5px 16px",
                                                                cursor: "pointer",
                                                                border: "none",
                                                                fontSize:"12px",
                                                                fontWeight:600
                                                        }}
                                                >
                                                        {label === "all" ? "All" : label === "week" ? "Week" : "Month"}
                                                </button>
                                        ))}
                                </div>
                        </Card></div>
        );
}

export default CardPage;