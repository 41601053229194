import React, { useState } from "react";
import SubscriptionModal from "./SubscriptionModal";
import SubscriptionUserDetail from "./SubscriptionUserDetail";
const SubscriptionDetail = ({ SubscriptionDetails ,SubscribedDetails,Upgradeplans, SubscriptionUserdetailinfo}) => {
    
    const subscriptionProps = {
        Upgradeplans: Upgradeplans,
        SubscriptionUserdetailinfo: SubscriptionUserdetailinfo,
      };
  
  const [isModalOpen, setModalOpen] = useState(false);
  const [ShowSubscribedUSerdetail,SetShowSubscribedUSerdetail]=useState(true)
  const formatDate = (dateString) => {
   
    const date = new Date(dateString);
  
   
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };
  const startingdate =formatDate(SubscribedDetails?.data.attributes.started_at)
  const expiredate = formatDate(SubscribedDetails?.data.attributes.expires_at) 

  const handleClose = () => {
    setModalOpen(!true);
   
  };

  const handleAddEquipment = () => {
    setModalOpen(true);
   
  };




  const discountedPrice = (SubscriptionDetails.discount / 100) * (SubscriptionDetails.price==="null" ? 0 :SubscriptionDetails.price);

   

  return (
    <> 
     { ShowSubscribedUSerdetail && (<><h1>Checkout</h1>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "left", gap: "20px" }}>
      <div className="subsc-detail-container">
        <div className="item">
          <div className="item-1">
            <div className="details-heading">Selected Plan</div>
            <div className="details-subheading">{SubscriptionDetails.name}</div>
          </div>
          <div className="price">
            <img
              src="/images/image_vector.jpg"
              alt="icon"
              style={{ marginRight: "10px" }}
            />
            <span style={{ color: "green", fontSize: "24px" }}>
            {SubscriptionDetails.price === "null" ? "FREE" : SubscriptionDetails.price}
            </span>
          </div>
        </div>
        <div className="item">
          <div className="item-1">
            <div className="details-heading2">Plan Duration</div>
            <div className="details-subheading">
              {SubscriptionDetails.month} Month(s)
            </div>
          </div>
        </div>
        <div className="item">
          <div className="item-1">
            <div className="details-heading3">Expiry date will be</div>
            <div className="details-subheading">
           {expiredate}
              <span className="fromtoday"> /from today</span>
            </div>
          </div>
        </div>
        </div>
        <div>
          <div className="deatials-right-container">
            <div className="detail-right-header">PACKAGE DETAIL</div>
            <hr />
            <div className="detail-right-row">
              <span className="detail-plan-name">{SubscriptionDetails.name}</span>
              <span>{SubscriptionDetails.price === "null" ?  "00.00" : SubscriptionDetails.price}</span>
            </div>
            <div className="detail-right-row small-text">
              <span>({SubscriptionDetails.month} months)</span>
            </div>
            <div className="detail-right-row">
              <span style={{ color: "green" }}>
                Discount ({SubscriptionDetails.discount}%)
              </span>
              <span style={{ color: "green" }}>{ discountedPrice===0 ? "NA" : discountedPrice}</span>
            </div>
            <div className="detail-right-row">
              <span>VAT (0%)</span>
              <span>NA</span>
            </div>
            <div className="detail-total-container">
              <div className="detail-right-total">
                <span>Total</span>
                <span>00.00 SAR</span>
              </div>
              <div className="detail-right-row small-text">
                <span>Starting {startingdate}</span>
                <span>00.00 SAR/Yr</span>
              </div>
            </div>
            <button className="confirm-button" onClick={()=>handleAddEquipment()}>Confirm Payment</button>
          </div>
        </div>
        </div>
        <div>
      {isModalOpen && (
        <SubscriptionModal SetShowSubscribedUSerdetail={SetShowSubscribedUSerdetail} onClose={handleClose} onAddEquipment={handleAddEquipment} setModalOpen={setModalOpen}/>
      )}
    </div></>)

  }
    
    
    </>
  );
};

export default SubscriptionDetail;
