import React, { useEffect, useState } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import instance from '../../shared/services/main';
import SubscriptionDetail from './SubscriptionDetail';
import DOMPurify from 'dompurify';
import SubscriptionUserDetail from './SubscriptionUserDetail';

function Subscriptions() {
  const [plans, setPlans] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsToShow = 3;
  const [Showselectedplan, setShowselectedplan] = useState(true);
  const [SubscriptionDetails, setSubscriptionDetails] = useState(null);
  const [SubscribedDetails, setSubscribedDetails] = useState(null);
  const [SubscriptionUserdetailinfo, setSubscriptionUserDetailinfo] = useState({
    is_active: false, 
    data: [], 
  });
  const [Upgradeplans, setUpgradeplans] = useState();
     console.log("Subscribeddetailinfo",SubscriptionUserdetailinfo,)
  const subUserdetail = async () => {
    try {
      const response = await instance.get('/bx_block_plan/subscriptions/user_plan_benefits');
      const data = response.data;
      setSubscriptionUserDetailinfo(data || { is_active: false, data: [] }); 
    } catch (err) {
      console.error('Error fetching subscription user details:', err.message, err.response?.data);
      setError('Failed to fetch subscription user details.');
      setSubscriptionUserDetailinfo({ is_active: false, data: [] }); 
    }
  };

  const fetchSubscriptionPlans = async () => {
    try {
      const response = await instance.get('/bx_block_plan/plans');
      const data = response.data.data || [];
      const formattedPlans = {};
      setUpgradeplans(data);

      data.forEach((plan) => {
        formattedPlans[plan.id] = {
          name: plan.attributes.name,
          price: `${plan.attributes.price_monthly}`,
          month: plan.attributes.months,
          plantype: plan.attributes.plan_type,
          features: plan.attributes.benefits || [],
          Activeplan: plan.attributes.active,
          comingsoon: plan.attributes.coming_soon,
          discount: plan.attributes.discount_percentage,
          planID: plan.id,
        };
      });

      setPlans(formattedPlans);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching subscription plans:', err.message, err.response?.data);
      setError('Failed to fetch subscription plans.');
      setLoading(false);
    }
  };

  const subscribeToPlan = async (subscriptionID) => {

    try {

      const response = await instance.post(`/bx_block_plan/subscriptions`, {
        subscription: {
          plan_id: subscriptionID,
        }
      });
      setSubscribedDetails(response.data)
     


      return response.data;
    } catch (error) {

      console.error(
        `Error while subscribing to the plan with ID ${subscriptionID}:`,
        error.response?.data || error.message
      );


      throw new Error(
        error.response?.data?.message || "Failed to subscribe to the plan."
      );
    }
  };



  const rawHTML = '<div style="color: red;">This is raw HTML content!</div>';
  const safeHTML = DOMPurify.sanitize(rawHTML);

  useEffect(() => {
    fetchSubscriptionPlans();
    subUserdetail();
  }, []);

  if (loading) return <div>Loading...</div>;

  const keys = Object.keys(plans || {});
  const maxIndex = Math.max(0, keys.length - itemsToShow);

  const handleLeftArrow = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : maxIndex));
  };

  const handleRightArrow = () => {
    setCurrentIndex((prevIndex) => (prevIndex < maxIndex ? prevIndex + 1 : 0));
  };

  const visibleItems = keys.slice(currentIndex, currentIndex + itemsToShow);

  const handleselect = (item) => {
    setSubscriptionDetails(item);
    setShowselectedplan(false);
    subscribeToPlan(item.planID);
  };

  const subscriptionProps = {
    Upgradeplans: Upgradeplans,
    SubscriptionUserdetailinfo: SubscriptionUserdetailinfo,
  };

  const subscriptiondetailprops = {
    SubscriptionDetails: SubscriptionDetails,
    SubscribedDetails: SubscribedDetails,
    Upgradeplans: Upgradeplans,
    SubscriptionUserdetailinfo: SubscriptionUserdetailinfo,
  };

  return (
    <>
      <Header />
      <div className="blank"></div>
      <div className="container py-4">
        <div className="row">
          <div className="col-md-3">
            <Sidebar id="4" />
          </div>
          <div className="col-md-9">
            <div className="rightSide">
              <div className="breadcrumbs">
                <span className="active-page">
                  {Showselectedplan ? (
                    "Subscription"
                  ) : (
                    <>
                      <span
                        className="clickable"
                        onClick={() => setShowselectedplan(true)}
                        style={{ cursor: "pointer" }}
                      >
                        Subscription
                      </span>
                      /Checkout
                    </>
                  )}
                </span>
              </div>
              {SubscriptionUserdetailinfo?.is_active ? (
                <SubscriptionUserDetail {...subscriptionProps} />
              ) : (
                <div>
                  {Showselectedplan ? (
                    <div className="subscription-plans-container">
                      <div
                        className="breadcrumbs"
                        style={{ textAlign: 'center', justifyContent: 'center' }}
                      >
                        <span className="subscription-head">Choose Subscription Plan</span>
                      </div>
                      <div className="enjoy-paragraph">
                        <p
                          style={{
                            textAlign: 'center',
                            justifyContent: 'center',
                            marginBottom: '0rem',
                          }}
                          className="subscription-sub-heading"
                        >
                          Enjoy our freemium plan for 1 year, soon you can get benefits of our
                          paid plans with extra benefits.
                        </p>
                      </div>
                      <div className="subscription-plans">
                        {visibleItems.map((key, index) => {
                          const plan = plans[key];
                          return (
                            <div
                              className={`plan-card ${index === 1 ? 'hovered' : ''}`}
                              key={key}
                            >
                              <div className="plan-card-deatil2">
                                {plan.price === "null" && (
                                  <div className="trendingImg">
                                    <img
                                      src="/images/image_trending_tag.svg"
                                      alt="trending"
                                    />
                                  </div>
                                )}
                                <div className="plan-title">{plan.name}</div>
                                <p className="plan-prices">
                                  <img
                                    src="/images/image_vector.jpg"
                                    alt="icon"
                                    style={{ marginRight: '10px' }}
                                  />
                                  {plan.price === "null" ? "FREE" : plan.price}
                                </p>
                                <div className="plan-month">{plan.month} Month</div>
                                <div className="feature-box">
                                  <div className="plan-plantype">
                                    {plan.plantype === 'free'
                                      ? 'Freemium Modal:'
                                      : 'Premium Modal:'}
                                  </div>
                                  <div className="plan-feature">
                                    {plan.features.map((feature, idx) => (
                                      <div
                                        key={idx}
                                        dangerouslySetInnerHTML={{ __html: feature.description }}
                                      />
                                    ))}
                                  </div>
                                </div>
                                <button
                                  className="subscribe-button"
                                  onClick={() =>
                                    !plan.comingsoon && handleselect(plan)
                                  }
                                  disabled={plan.comingsoon}
                                >
                                  <p className="subscribe-content">
                                    {plan.comingsoon ? "Coming Soon" : "Subscribe Now"}
                                  </p>
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="arrows-container">
                        <div className="arrow left" onClick={handleLeftArrow}>
                          &lt;
                        </div>
                        <div className="arrow right" onClick={handleRightArrow}>
                          &gt;
                        </div>
                      </div>
                    </div>
                  ) : (
                    <SubscriptionDetail {...subscriptiondetailprops} />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Subscriptions;
