import React from "react";

import { useState } from "react";
const SubscriptionUserDetail = ({ Upgradeplans, SubscriptionUserdetailinfo}) => {

    const visibleUpgradePlans = Upgradeplans?.Upgradeplans?.filter(
        (plan) =>
          plan.attributes.name !==
          SubscriptionUserdetailinfo?.subscriptions?.plan_name
      ) || [];
    
      const [currentIndex, setCurrentIndex] = useState(0); 
    
      const itemsPerPage = 2; 
     
      const handleNext = () => {
      
         if(currentIndex+itemsPerPage < visibleupgradepalns.length)
          setCurrentIndex((prev) => prev + 1);
        
      };
    
  
      const handlePrevious = () => {

        if(currentIndex>0){
          setCurrentIndex((prev) => prev - 1);}
        
      };
    const visibleupgradepalns = Upgradeplans
    const [showModal, setShowModal] = useState(false);
   

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
    const confirmAction = () => {

        setShowModal(false);
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { month: "short", day: "numeric", year: "numeric" };
        return new Intl.DateTimeFormat("en-US", options).format(date);
    };
    const startingdate = formatDate(SubscriptionUserdetailinfo.subscriptions.start_date)
    const expiredate = formatDate(SubscriptionUserdetailinfo.subscriptions.end_date)
    const [isChooseUserOpen, setIsChooseUserOpen] = useState(false);

    const openChooseUser = () => setIsChooseUserOpen(true);
    const closeChooseUser = () => setIsChooseUserOpen(false);
    return (
        <>
            <div className="subs-user-detail">
                <div className="subs-deatil">
                    <div className="subs-detail-child1"><p>Subscription Status</p></div>
                    <div className="subs-detail-child2"><p> <img src="images/image_vector(4).svg" alt="iconn" />  Active</p></div>
                </div>
                <div className="subs-deatil">
                    <div className="subs-detail-child1"><p>Active Since Date</p></div>
                    <div className="subs-detail-child2"><p>{startingdate}</p></div>
                </div>
                <div className="subs-deatildays">
                    <div className="subuser-days">
                        <div className="subs-detail-child1-expiredon"><p>Expired On</p></div>
                        <div className="subs-detail-child2"><p>{expiredate}</p></div>
                    </div>

                    <div className="daysleft">
                        <p className="days-ledt-user"> {SubscriptionUserdetailinfo.days_left}</p>
                        <p>days left</p>
                    </div>
  </div>
            </div>
            <div className="subsuser-container">
                <div className="subuser-innercontainer">
                    <div className="inner-head1">CURRENT PLAN DETAILS
                        <hr></hr>
                        <div className="subuser-innercontainer1">
                            <div className="subuser-innerhead">
                                <div className="subuser-inner-content">
                                    <div className="subsuser-packagename">
                                        Package Name
                                    </div>
                                    <span className="subuser-free">  <img src="/images/image_vector.jpg" alt="icon" /> {SubscriptionUserdetailinfo.subscriptions.price_monthly === null ? "FREE" : SubscriptionUserdetailinfo.subscriptions.price_monthly}</span>

                                </div>
                                <div className="subuser-inner-content">
                                    <div className="subsuser-packagename-planname">
                                        {SubscriptionUserdetailinfo.subscriptions.plan_name}
                                    </div>
                                    <span> Duration:{SubscriptionUserdetailinfo.subscriptions.months} months</span>
                                </div>
                            </div>
                            <hr></hr>

                            <p className="subsuser-benifit">Benefits   <span> <img
                                src="/images/image_Chevron down_icon.svg"
                                alt="icon"
                                style={{ marginRight: "10px" }}
                            /></span></p>
                            <div className="subuser-plans-features">
                                {
                                    SubscriptionUserdetailinfo.subscriptions.benefits.map((feature, i) => (<div key={i}>{feature.description}</div>))
                                }

                            </div>


                        </div>


                    </div>


                </div>
                <div className="subsuser-button">

                    <button className="subuser-buttons1" onClick={() => { openChooseUser() }} >Upgrade</button>
                    <button className="subuser-buttons2" onClick={() => openModal()}> Cancel</button>
                </div>
                <div className="subuser-bottom">
                    You’re using free plan, upgrade your plan
                    to get more benefits


                </div>
                <div className="subuser-lastbottom">BILLING HISTORY</div>
                <hr></hr>
                <div className="lastbottom-content">
                    <p>{startingdate}</p>
                    <p>SAR 0</p>
                    <p>{SubscriptionUserdetailinfo.subscriptions.plan_name}</p>
                </div>

            </div>
            <div className="subsusermodal-container">

                {showModal && (
                    <div className="subsusermodal-overlay">
                        
                        <div className="subsusermodal-content">
                        <div className="subuser-crooss-button"><img onClick={closeModal}src="/images/close_icon.png" alt="icon"/></div>
                            <h2 className="subsusermodal-title">Final Confirmation!</h2>
                            <p className="subsusermodal-message">
                                Are you really want to cancel this subscription
                            </p>
                            <div className="subsusermodal-actions">
                                <button
                                    className="subsusermodal-button subsusermodal-no-button"
                                    onClick={closeModal}
                                >
                                    No
                                </button>
                                <button
                                    className="subsusermodal-button subsusermodal-yes-button"
                                    onClick={() => { confirmAction() }}
                                >
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div>


                {isChooseUserOpen && (
                    <div className="chooseuser-overlay">
                        <div className="chooseuser-content">
                       <div className="chooseuser-icon-button"> < img   onClick={closeChooseUser}src="/images/close_icon.png" alt="icon"/></div>
                            <div className="chooseuser-heading">
                                <div className="chooseuser-heading-content"> Stay Tuned!</div>
                                <div className="chooseuser-subheading-content">Enjoy our Freemium plan for 1 Year, soon you can get benefits of our paid plans with extra benefits</div>

                            </div>
                            <div className="chooseuser-detail">
                       <div className="chooseuser-arrow-button"> 
                       <button 
        onClick={()=>handlePrevious()}
       
        className="chooseuser-arrow-button"
      >
          &lt;  
      </button>

                       </div>
                                {visibleupgradepalns?.filter((plan) => plan.attributes.name !== SubscriptionUserdetailinfo.subscriptions.plan_name).slice(currentIndex, currentIndex + itemsPerPage)
                                    .map((plan, index) => (<div key ={index} className="visibleupgradepalns-container">
                                        <div className="chooseuser-name">{plan.attributes.name}</div>
                                        <div>
                                            <div className="chooseuser-price"><span><img src="/images/image_vector.jpg" alt="icon" style={{ marginRight: '10px' }} /></span>{plan.attributes.price_monthly === null ? "FREE" : plan.attributes.price_monthly}</div>
                                            <div className="chooseuser-months">{plan.attributes.months} months</div>
                                        </div>
                                        <div className="chooseuser-benefits">
                                            {plan.attributes.plan_type === 'free' ? 'Freemium Modal:' : 'Premium Modal:'}
                                            <div className="chooseuser-benefits-details">
                                                {plan.attributes.benefits.map((features, index) => (<div className="chooseuser-features">{features.description}</div>))}
                                            </div>

                                        </div>
                                        <button className="chooseuser-button" >{plan.attributes.coming_soon ? "Coming Soon" : "Subscribe Now"} </button>

                                    </div>))}
                                    <button
        onClick={()=>handleNext()}
      
        className="chooseuser-arrow-button"
      >
          &gt; 
      </button>



                            </div>
                            <button className="chooseuser-close-button" onClick={closeChooseUser}> Okay </button>

                        </div>
                    </div>
                )}
            </div>

        </>
    );
};

export default SubscriptionUserDetail;
