import React from "react";
import { useNavigate } from "react-router-dom";



const SubscriptionModal = ({ onClose, onAddEquipment }) => {
  const navigate = useNavigate();
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        <div className="modal-icon">
          <img src="/images/image_Success.svg" alt="Success Icon" />
        </div>
        <div className="modal-sub-content">
     <div className="modal-sub-heading">  <p className="modal-sub-para"> Congratulations</p></div>
      <div className="modal-sub-heading1">  <p>Thank you for choosing Alyya</p></div>
       <div className="modal-sub-heading2">
       <p>
          Your subscription has been successfully added to your account.
          <br />
          Click on Add Equipment (link to the add equipment page).
        </p>

       </div>
        </div>
        <div className="modal-buttons">
         <div> <button className="modal-sub-button" onClick={onClose}>
            Close
          </button></div>
    <div> <button className="modal-sub-button1"  onClick={() => {
                                              onAddEquipment();  
                                              navigate('/manage-equipment');      
                                            }}>
            Add Equipments
          </button> </div>  
        </div>
      </div>
    </div>
  
  );
};

export default SubscriptionModal;
